<template>
  <div class="contacts">
    <div class="wrap">
      <h2>Контакти</h2>

      <div class="holder">
        <div class="text">
          <div class="block" data-aos="fade-left">
            <h4>Директор</h4>
            <p class="name">Петро Залізний</p>
            <a href="tel:+380674103305">+380 (67) 410 33 05</a>
            <a href="mailto:sokolyatko_70@ukr.net">sokolyatko_70@ukr.net</a>
          </div>
          <div class="block" data-aos="fade-left" data-aos-delay="500">
            <h4>Бухгалтер</h4>
            <p class="name">Лариса Мельник</p>
            <a href="tel:+380680127070">+380 (68) 012 70 70</a>
            <a href="mailto:sokolyatko_70@ukr.net">sokolyatko_70@ukr.net</a>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10268.975068502656!2d28.5172462!3d49.9505296!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x37f049732c162201!2sChildren%20-%20Youth%20camp%20%22Sokolyatko%22!5e0!3m2!1sru!2sua!4v1618298946433!5m2!1sru!2sua"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          data-aos="fade-right"
        >
        </iframe>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .contacts {
    .wrap {
      .holder {
        position: relative;
        width: 100%;
        max-width: 100vw;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        place-items: center;
        grid-gap: 32px;
        min-height: calc(100vh - 200px);

        iframe {
          position: relative;
          display: block;
          width: 100%;
          height: 500px;
        }

        .text {
          .block {
            display: grid;
            grid-gap: 8px;
            margin: 16px 0;

            .name {
              color: #333;
            }
          }
        }
      }
    }
  }
</style>
